import { getURL } from "../utils/get-url";
import { siteConfig } from "./siteConfig";

const dashboardRoutes = {
  dashboard: "/dashboard",
  templates: "/dashboard/templates",
  collections: "/dashboard/collections",
  customWorkflow: "/dashboard/custom-workflow",
  recipets: "/dashboard/recipets",
  settings: "/dashboard/settings",
  billing: "/dashboard/billing",
  postById: "/dashboard/collections/:collectionId/:postId",
  integrations: "/dashboard/integrations",
  chat: "/dashboard/chat",
  AIImage: "/dashboard/ai-image",
  AIImageRestorer: "/dashboard/ai-image?tab=restorer",
  voice: "/dashboard/brand-voice",
  infoBase: "/dashboard/brand-voice?tab=info-base",
  persona: "/dashboard/persona",
  workflows: "/dashboard/workflows",
  workflowBySlug: "/dashboard/workflows/:slug",
  workspace: "/workspace",
  pastWorkflows: "/workflows/all",
  bulkMode: "/bulk-create",
};

export const compareRoutes = {
  chatgpt: "/compare/junia-vs-chatgpt",
  filechat: "/compare/junia-vs-other-files-chat",
};

export const serverRoutes = {
  front: "/",
  login: "/login",
  register: "/register",
  blog: "/blog",
  templates: "/templates",
  art: "/art",
  chat: "/chat",
  imageRestore: "/image-restorer",
  editor: "/ai-editor",
  features: "/features",
  payment: "/payment",
  security: "/security",
  cookiePolicy: "/cookie",
  privacyPolicy: "/privacy",
  terms: "/terms",
  useCases: "/use-cases",
  guides: "/guides",
  pricing: "/pricing",
  // carousel: "/react-multi-carousel",
  contact: "/contact-us",
  affiliate: "/affiliate",
  aiDetector: "/tools/ai-detector",
  musicGen: "/tools/music-generator",
  styleMatch: "/brand-voice",
  citation: "/tools/citation-generator",
  docs: "/docs",
  announcements: "/docs/announcements",
  ...compareRoutes,
};

export const serverRoutesConfig = {
  ...serverRoutes,
  templateBySlug: `${serverRoutes.templates}/[slug]`,
  blogBySlug: `${serverRoutes.blog}/[slug]`,
  featureBySlug: `${serverRoutes.features}/[slug]`,
  shareArticleRouteById: `/public/article/[id]`,
  docsBySlug: "/docs/[slug]",
  docsByCategory: "/docs/category/[slug]",
};

export const blogRouteBySlug = (slug: string) => {
  return `${serverRoutes.blog}/${slug}`;
};

export const fromParams = "from";

export const priceIdParams = "plan";

export const buildFromParams = (from: string) => {
  return `${fromParams}=${encodeURIComponent(from)}`;
};

export const templateServerRouteBySlug = (slug: string) => {
  return `${serverRoutes.templates}/${slug}`;
};

export const workflowRouteBySlug = (slug: string) => {
  return `${dashboardRoutes.workflows}/${slug}`;
};

export const dashboardRoutesValues = Object.values(dashboardRoutes);

export const dashboardRouteConfig = {
  ...dashboardRoutes,
  collectionById: `${dashboardRoutes.collections}/:collectionId`,
  postById: `${dashboardRoutes.collections}/:collectionId/:postId`,
  templateById: `${dashboardRoutes.templates}/:templateId`,
  integrationByPlatformType: `${dashboardRoutes.integrations}/:platformType`,
  upgradeRoute: `${dashboardRoutes.billing}#pricing`,
  workflowById: `${dashboardRoutes.workflows}/:type/:id`,
};

export const dashboardRouteValues = Object.values(dashboardRouteConfig);

export const serverRoutesValues = Object.values(serverRoutesConfig);

export const templateByIdRoute = (id: string) =>
  `${dashboardRouteConfig.templates}/${id}`;

export const collectionByIdRoute = (id: string) =>
  `${dashboardRouteConfig.collections}/${id}`;

export const postByIdRoute = (collectionId: string, postId: string) =>
  `${dashboardRouteConfig.collections}/${collectionId}/${postId}`;

export const workflowByIdRoute = (type: string, id: string) =>
  `${dashboardRouteConfig.workflows}/${type}/${id}`;

export const integrationByPlatformType = (type: "contentful") =>
  `${dashboardRouteConfig.integrations}/${type}`;

export const emailResubscribeRouteByTokenId = (tokenId: string) =>
  `/email/resubscribe/${tokenId}`;

export const fullEmailUnsubscribeRouteByTokenId = (tokenId: string) =>
  getURL(`email/unsubscribe/${tokenId}`);

export const fullShareArticleRouteById = (id: string) => {
  return `${siteConfig.url}/public/article/${id}`;
};

export const docCategoryRouteBySlug = (slug: string) => {
  return `${serverRoutes.docs}/category/${slug}`;
};

export const docRouteBySlug = (slug: string) => {
  return `${serverRoutes.docs}/${slug}`;
};
