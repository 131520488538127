import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type {
  SessionType,
  ClientUserDetails,
} from "client-server-shared/types/auth-type";
import type { SubscriptionWithPrice } from "client-server-shared/types/payment";
import type { Notification } from "client-server-shared/types/notifications";
import { hydrate } from "../actions";

interface UserState {
  session: SessionType | null;
  userDetails: ClientUserDetails | null;
  activePlan: SubscriptionWithPrice | null;
  notifications: Notification[] | null;
}

const initialUserState: UserState = {
  session: null,
  userDetails: null,
  activePlan: null,
  notifications: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setSession: (state, action: PayloadAction<SessionType | null>) => {
      state.session = action.payload;
    },
    setUserDetails: (
      state,
      action: PayloadAction<ClientUserDetails | null>
    ) => {
      if (state.userDetails && action.payload) {
        if (state.userDetails.onboardingOption) {
          const option = state.userDetails.onboardingOption;
          state.userDetails = {
            ...state.userDetails,
            ...action.payload,
            onboardingOption: option,
          };
          return;
        }
      }
      state.userDetails = action.payload;
    },
    updateUserDetails: (
      state,
      action: PayloadAction<Partial<ClientUserDetails>>
    ) => {
      if (state.userDetails) {
        state.userDetails = {
          ...state.userDetails,
          ...action.payload,
        };
      }
    },
    contentfulAuthSucceeded: (state) => {
      if (state.userDetails) {
        state.userDetails.encrypted_contentful_access_token = true;
      }
    },
    wordpressAuthSucceeded: (state) => {
      if (state.userDetails) {
        state.userDetails.encrypted_wordpress_access_token = true;
      }
    },
    setSubscription: (
      state,
      action: PayloadAction<SubscriptionWithPrice | null>
    ) => {
      state.activePlan = action.payload;
    },
    setNotifications: (state, action: PayloadAction<Notification[] | null>) => {
      state.notifications = action.payload;
    },
    markNotificationAsSeen: (state, action: PayloadAction<string>) => {
      if (state.notifications) {
        state.notifications = state.notifications.filter(
          (n) => n.id !== action.payload
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (
        state.session?.user?.id ||
        state.userDetails ||
        state.activePlan ||
        state.notifications
      ) {
        return state;
      }
      return action.payload.user;
    });
  },
});

export const {
  updateUserDetails,
  setSession,
  setSubscription,
  contentfulAuthSucceeded,
  setUserDetails,
  wordpressAuthSucceeded,
  setNotifications,
  markNotificationAsSeen,
} = userSlice.actions;

export default userSlice.reducer;
