import { siteConfig } from "client-server-shared/config/siteConfig";
import Image from "next/image";
import React from "react";

import defaultLogo from "public/logo.svg";
import whiteLogo from "public/logo-white.png";

export const WhiteLogo = ({ height = 40, width = 132 }) => {
  return (
    <Image
      height={height}
      width={width}
      priority
      src={whiteLogo}
      style={{
        objectFit: "contain",
      }}
      alt={siteConfig.whiteLogo.alt}
    />
  );
};

export const LogoIconOnly = ({
  className,
  ...rest
}: {
  className?: string;
  height?: number;
  width?: number;
}) => {
  return (
    <Image
      height={50}
      width={77}
      priority
      className={className}
      src={whiteLogo}
      alt={siteConfig.logoIcon.alt}
      {...rest}
      style={{
        objectFit: "contain",
        minHeight: rest.height || 50,
        minWidth: rest.width || 77,
        ...(rest.style || {}),
      }}
    />
  );
};
