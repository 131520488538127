export const drawerWidth = 230;
export const collpseDrawerWidth = 80;
export const drawerInnerWidth = 180;
export const mainContentSpacing = 24;
export const appbarHeight = 64;

export const primaryScrollbar = {
  scrollbarColor: "grey transparent",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "rgb(255 255 255 / 10%)",
    borderRadius: "20px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "grey",
    backgroundImage: "linear-gradient(45deg, #00aeff, #a68eff)",
    borderRadius: "20px",
  },
};

export const secondaryScrollbar = {
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ced5df",
    borderRadius: "20px",
  },
};

export const heroSpacing = {
  padding: {
    xs: "0 0 70px 0",
    sm: "0 0 70px 0",
    md: "0 0 70px 0",
    lg: "70px 0 70px 0",
  },
};
