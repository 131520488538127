import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import collections, { collectionsAdapter } from "./collections/collections";
import settings from "./settings";
import imports from "./imports";
import processingLogs from "./processing-log";
import notifications from "./notifications";
import modals from "./modals";
import type { InitialDataProps } from "client-server-shared/types/collection";
import { loadCollectionState } from "./collections/collection";
import authors from "./authors";
import user from "./user";
import categories from "./categories";
import templateTranslations from "./template-translation";
import collectionExtra from "./collections/collection-extra";
import workflows from "./workflows";

import { createWrapper } from "next-redux-wrapper";
import { authExpired, logout } from "./actions";

const createPreloadState = ({
  collectionById,
  collections,
}: InitialDataProps) => {
  const initialState = {};

  if (collections) {
    const collectionsToAdd = collections.map((collectionToAdd) =>
      loadCollectionState(collectionToAdd)
    );
    const collectionsState = collectionsAdapter.upsertMany(
      collectionsAdapter.getInitialState(),
      collectionsToAdd
    );
    initialState["collections"] = collectionsState;
  } else if (collectionById) {
    const collectionsToAdd = [collectionById].map((collectionToAdd) =>
      loadCollectionState(collectionToAdd)
    );
    const collectionsState = collectionsAdapter.upsertMany(
      collectionsAdapter.getInitialState(),
      collectionsToAdd
    );
    initialState["collections"] = collectionsState;
  }
  return initialState;
};

const rootReducer = combineReducers({
  categories,
  settings: settings,
  collections: collections,
  imports,
  authors,
  processingLogs,
  notifications,
  modals,
  user,
  templateTranslations,
  collectionExtra,
  workflows,
});

const resettableRootReducer = (
  state: ReturnType<typeof rootReducer>,
  action: AnyAction
) => {
  if (logout.match(action) || authExpired.match(action)) {
    return rootReducer(
      undefined as unknown as ReturnType<typeof rootReducer>,
      action
    );
  }
  return rootReducer(state, action);
};

export const makeStore = (dataProps: InitialDataProps) => {
  const preloadedState = createPreloadState(dataProps);
  return configureStore({
    reducer: resettableRootReducer,
    preloadedState,
    /*
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenerMiddleware.middleware),
      */
    devTools: process.env.NODE_ENV === "development" ? true : false,
  });
};

export const wrapper = createWrapper<ReturnType<typeof makeStore>>(makeStore, {
  serializeState: (state) => JSON.stringify(state),
  deserializeState: (state) => JSON.parse(state),
});
