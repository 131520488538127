import { isCn } from "client-server-shared/constants";
import { drawerWidth } from "./modules/themes/sytle-constants";

export const collectionPageId = "collection-page";

export const mainPageWidth = `calc(100% - ${drawerWidth}px)`;

export const isProd = process.env.NEXT_PUBLIC_ENV_NAME === "prod";

let markdownFileName = "content.md";
let custom = "custom";

if (isCn()) {
  custom = "自定义";
}

export { custom };
