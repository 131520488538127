import { createEntityAdapter, createSlice, original } from "@reduxjs/toolkit";
import { hydrate } from "../actions";

import {
  addCollections,
  collectionsLoaded,
  removeCollectionById,
} from "./actions";

import collectionReducer, {
  loadCollectionState,
  CollectionState,
} from "./collection";

export const collectionsAdapter = createEntityAdapter<CollectionState>({
  selectId: (entity) => entity.data.clientId,
  sortComparer: (a, b) =>
    new Date(b.data.createdAt).getTime() - new Date(a.data.createdAt).getTime(),
});

const collectionsSlice = createSlice({
  name: "collections",
  initialState: collectionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (state.ids.length > 0) {
        return state;
      }
      return action.payload.collections;
    });
    builder.addCase(collectionsLoaded, (state, action) => {
      const collections = action.payload;
      const collectionsToAdd = collections.map((collectionToAdd) =>
        loadCollectionState(collectionToAdd)
      );
      const newState = collectionsAdapter.upsertMany(state, collectionsToAdd);
      return newState;
    });
    builder.addCase(removeCollectionById, (state, action) => {
      const newState = collectionsAdapter.removeOne(
        state,
        action.payload.collectionId
      );
      return newState;
    });
    builder.addCase(addCollections, (state, action) => {
      const collections = action.payload;
      const collectionsToAdd = collections.map((collectionToAdd) =>
        loadCollectionState(collectionToAdd)
      );
      const newState = collectionsAdapter.upsertMany(state, collectionsToAdd);
      return newState;
    });
    builder.addDefaultCase((state, action) => {
      const selectedCollectionId = action.payload?.collectionId;
      if (!selectedCollectionId) {
        return state;
      }
      const selectedCollection = state.entities[selectedCollectionId];
      if (!selectedCollection) {
        return state;
      }
      state.entities[selectedCollectionId] = collectionReducer(
        selectedCollection,
        action
      );
      return state;
    });
  },
});

export default collectionsSlice.reducer;
