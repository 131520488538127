import React from "react";

import { CircularProgress } from "../loading";
import { Button, IconButton, ButtonProps } from ".";
import { Span } from "components/as-inline-span";
import { Typography } from "../Typography";

export interface ButtonWithLoadingProps extends ButtonProps {
  loading?: boolean;
}

export const ButtonWithLoading = React.forwardRef(
  (props: ButtonWithLoadingProps, ref) => {
    const { children, loading, ...rest } = props;
    return (
      <Button ref={ref} {...rest}>
        {loading ? (
          <CircularProgress size={props.size || 20} color="inherit" />
        ) : (
          <Span>{children}</Span>
        )}
      </Button>
    );
  }
);
ButtonWithLoading.displayName = "Button";

const emptyObject = {};
export const OutlineGreyButton = React.forwardRef(
  (
    props: ButtonWithLoadingProps & { asText?: boolean; active?: boolean },
    ref
  ) => {
    const { children, asText, active, ...rest } = props;

    return (
      <ButtonWithLoading
        ref={ref}
        variant="outlined"
        capitalize
        {...rest}
        sx={{
          fontWeight: 500,
          border: asText ? 0 : "1px solid #D6D6D6",
          color: "rgb(86, 99, 108)",
          whiteSpace: "nowrap",
          ...(active
            ? {
                background: "#CE2EDA",
                color: "text.white",
              }
            : {}),
          ...(rest.sx || {}),
          "&:hover": {
            border: asText ? 0 : "1px solid transparent",
            "& svg": {
              fill: "#9B4E97",
            },
            ...(active
              ? {
                  color: "rgb(86, 99, 108)",
                }
              : {}),
            ...(rest.sx?.background || rest.sx?.backgroundColor
              ? {
                  background: rest.sx?.background || rest.sx?.backgroundColor,
                }
              : {}),
            ...((rest.sx || {})["&:hover"] || emptyObject),
          },
        }}
      >
        {children}
      </ButtonWithLoading>
    );
  }
);

export const PrimaryActionButton = React.forwardRef(
  (props: ButtonWithLoadingProps, ref) => {
    const { children, ...rest } = props;
    return (
      <ButtonWithLoading
        ref={ref}
        variant="outlined"
        capitalize
        {...rest}
        sx={{
          fontWeight: 500,
          color: "text.white",
          backgroundColor: "#40B5B5",
          border: 0,
          "&:hover": {
            backgroundColor: "#40B5B5",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 2px 10px 0px",
            border: 0,
          },
          ...(rest.sx || {}),
        }}
      >
        {children}
      </ButtonWithLoading>
    );
  }
);

export const IconButtonWithLoading = React.forwardRef((props: any, ref) => {
  const { children, loading, ...rest } = props;
  return (
    <IconButton ref={ref} {...rest}>
      {loading ? (
        <CircularProgress size={props.size || 20} color="inherit" />
      ) : (
        <Span>{children}</Span>
      )}
    </IconButton>
  );
});

export const BlackButtonWithLoading = React.forwardRef(
  (props: ButtonWithLoadingProps, ref) => {
    const { children, ...rest } = props;

    return (
      <ButtonWithLoading
        capitalize
        {...rest}
        sx={{
          background: rest.disabled ? "#f2f2f2" : "#111827",
          color: rest.disabled ? "rgba(0, 0, 0, 0.26)" : "text.white",
          border: rest.disabled ? "1px solid rgb(229 231 235)" : 0,
          borderRadius: "10px",
          textDecoration: "none",
          "&:hover": {
            background: "#111827",
            opacity: 0.8,
            border: 0,
          },
          ...(rest.sx || {}),
        }}
        ref={ref}
      >
        {children}
      </ButtonWithLoading>
    );
  }
);
