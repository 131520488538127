import { ContentfulContentType, EnhanceContentField } from "./contentful-types";

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export enum Locale {
  en = "en-US",
}

export const updatableAuthorFields = [
  "name",
  "picture",
  "bio",
  "designation",
  "email",
  "categories",
];
export const creatableAuthorFields = [
  ...updatableAuthorFields,
  "entryId",
  "clientId",
  "platform",
  "categories",
];

export const creatableSettingsFields = ["categories"];

export interface SettingsPostBody {
  categories?: string[];
}

export interface FilePostBody {
  fileName: string;
  fileType: "image/png" | "image/jpeg";
}

export const updatableSettingsFields = ["categories"];

export interface SettingsPatchBody {
  categories?: string[];
}

export type AuthorCreateBody = Omit<Author, "id">;
export type AuthorUpdateBody = Partial<Author> & {
  id: string;
};

export const authorFieldsUpsertNullToDbFormat: Record<
  string,
  (keyof Author)[]
> = {
  asNull: [],
  asString: ["entryId", "picture", "bio", "email", "assetId", "platform"],
  asArray: ["categories"],
  asOmitted: [],
};

export interface Author {
  id?: string;
  clientId: string;
  name: string;
  picture?: string | null;
  bio?: string | null;
  designation?: string | null;
  email?: string | null;
  categories?: string[];
  entryId?: string;
  assetId?: string;
  platform?: string;
}

export interface FeatureImage {
  assetId?: string;
  url?: string;
  alt?: string;
}

export type PostMetadata = { platform: "contentful"; id?: string };

export type InfoBaseItem =
  | {
      sourceIds?: string[];
      text?: string;
    }
  | string;

export interface PostConfig {
  description?: string;
  targetAudience?: string;
  writingStyle?: string;
  toneOfVoice?: string;
  startFromOutline?: boolean;
  wordsCount?: number;
  variationsCount?: number;
  constraintCategories?: boolean;
  categoriesList?: string[];
  pastConversations?: string;
  additionalInstructions?: string;
  samples?: InfoBaseItem;
  language?: string;
  purpose?: string;
  background?: InfoBaseItem;
  keywords?: string[];
  faqs?: { question: string; answer: string }[];
}

export const updatablePostFieldsInApi: (keyof Post)[] = [
  "title",
  "content",
  "categories",
  "metaTitle",
  "metaDescription",
  "slug",
  "tags",
  "authorId",
  "featureImage",
  "extendedData",
  "postMetadata",
  "postConfig",
  "date",
  "draft",
  "public",
  "templateId",
  "generatorOpen",
];
export const updatablePostFieldsInClient: (keyof Post)[] = [
  ...updatablePostFieldsInApi,
  "collectionId",
  "clientId",
  "id",
];
export const creatablePostFieldsInApi: (keyof Post)[] = [
  "clientId",
  "createdAt",
  ...updatablePostFieldsInApi,
];
export const creatablePostFieldsInClient: (keyof Post)[] = [
  "clientId",
  "createdAt",
  ...updatablePostFieldsInApi,
];

export const postFieldsUpsertNullToDbFormat: Record<string, (keyof Post)[]> = {
  asNull: ["extendedData", "featureImage", "postConfig", "postMetadata"],
  asRawNull: ["authorId"],
  asString: ["title", "metaTitle", "metaDescription", "slug", "date"],
  asOmitted: ["createdAt", "draft", "templateId", "generatorOpen"],
  asArray: ["categories", "tags", "content"],
};

export interface Post {
  id?: string;
  clientId: string;
  collectionId?: string;
  title?: string;
  templateId?: string;
  generatorOpen?: boolean;
  content?: { value: string }[];
  categories?: string[];
  draft?: boolean;
  metaTitle?: string | null;
  metaDescription?: string | null;
  slug?: string | null;
  tags?: string[] | null;
  public?: boolean;

  authorId?: string | null;

  featureImage?: FeatureImage | null;

  extendedData?: Record<string, unknown> | null;
  date?: string | null;
  postMetadata?: PostMetadata | null;
  postConfig?: PostConfig | null;
  createdAt?: string | null;
}

export interface SavedPost extends Post {
  id: string;
}

export interface FieldsMapping {
  [key: string]: EnhanceContentField;
}

export interface ContentfulMetadata {
  platform: "contentful";
  spaceId: string;
  environmentId: string;
  contentTypeId: string;
  fieldsMapping: FieldsMapping;
  contentTypes: ContentfulContentType[];
}

export interface ContentfulImportPostBody {
  metaData: {
    fieldMappings: FieldsMapping;
    contentTypes: ContentfulContentType[];
  };
}

export type CollectionMetaData = ContentfulMetadata;

export const updatableCollectionFieldsInApi = [
  "templateId",
  "locales",
  "title",
  "metaData",
  "draft",
  "autoSave",
];
export const creatableCollectionFieldsInApi = [
  "clientId",
  "createdAt",
  ...updatableCollectionFieldsInApi,
];

export const updatableCollectionFieldsInClient = [
  ...updatableCollectionFieldsInApi,
  "clientId",
];
export const creatableCollectionFieldsInClient = [
  "id",
  "clientId",
  "createdAt",
  ...updatableCollectionFieldsInApi,
];

export const collectionFieldsUpsertNullToDbFormat: Record<
  string,
  (keyof Collection)[]
> = {
  asNull: ["metaData"],
  asString: ["title", "templateId"],
  asArray: [],
  asOmitted: ["createdAt", "draft", "autoSave"],
};

export interface Collection {
  id?: string;
  clientId: string;
  templateId?: string | null;
  authorId?: string;
  draft?: boolean;
  autoSave?: boolean;
  title?: string;
  posts: Post[];
  metaData?: CollectionMetaData | null;
  createdAt?: string | null;
}

export interface SavedCollection extends Collection {
  id: string;
  posts: SavedPost[];
}

type OmitOptionalMetadata<T> = Overwrite<
  T,
  {
    createdAt?: string | null;
  }
>;

export type PostCreateBody = OmitOptionalMetadata<Omit<Post, "id">>;

export type CollectionCreateBody = OmitOptionalMetadata<
  Omit<Collection, "id" | "posts" | "authorId"> & {
    posts: PostCreateBody[];
  }
>;

export type PostUpdateBody = OmitOptionalMetadata<
  Partial<Post> & {
    id: string;
  }
>;

export type CollectionUpdateBody = OmitOptionalMetadata<Partial<Collection>> & {
  id: string;
  posts: PostUpdateBody | PostCreateBody[];
};
