import { isCn } from "client-server-shared/constants";

export const cnUrl = "https://www.generateforme.com";
export const enUrl = "https://www.generateforme.com";

const siteConfig = {
  title: "Instant Content Creation with AI - No Login Required",
  description:
    "Instantly generate over 50 types of content without the hassle of logging in. Save time and boost creativity.",
  email: "contact@junia.ai",
  logo: {
    url: "/logo.svg",
    alt: "GenerateForMe Logo",
  },
  whiteLogo: {
    url: "/logo-white.png",
    alt: "GenerateForMe Logo",
  },
  logoIcon: {
    url: "/logo-white.png",
    alt: "GenerateForMe Logo",
  },
  favicon: "/favicon.png",
  openGraphImage: "/og.png",
  url: enUrl,
  locale: "en",
  name: "GenerateForMe",
  links: {
    twitter: "https://twitter.com/junia_ai",
    facebook: "https://www.facebook.com/profile.php?id=100091403777685",
    youtube: "https://youtube.com/junia.ai",
    linkedin: "https://linkedin.com/junia.ai",
  },
};

export { siteConfig };
