export const locale = process.env.NEXT_PUBLIC_LOCALE;

export const isCn = () => locale === "cn";

let lang = "English";

if (isCn()) {
  lang = "Chinese";
}

export { lang };

export const languagesExampleByLanguage = {
  English: "English",
  Arabic: "العربية",
  Bengali: "বাংলা",
  Bulgarian: "български",
  Chinese: "中文",
  Croatian: "hrvatski",
  Czech: "čeština",
  Danish: "dansk",
  Dutch: "Nederlands",
  Estonian: "eesti keel",
  Finnish: "suomi",
  French: "français",
  Georgian: "ქართული",
  German: "Deutsch",
  Greek: "Ελληνικά",
  Hebrew: "עִבְרִית‎",
  Hindi: "हिन्दी",
  Hungarian: "Magyar",
  Indonesian: "Bahasa Indonesia",
  Italian: "Italiano",
  Japanese: "日本語",
  Korean: "한국어",
  Latvian: "Latviešu valoda",
  Lithuanian: "Lietuvių kalba",
  Malay: "Bahasa Melayu",
  Maltese: "Malti",
  Norwegian: "norsk (bokmål)",
  Polish: "polski",
  Portuguese: "português",
  Romanian: "română",
  Russian: "русский",
  Serbian: "српски језик",
  Slovak: "slovenčina",
  Slovenian: "slovenščina",
  Spanish: "español",
  Swedish: "svenska",
  Tamil: "தமிழ்",
  Thai: "ไทย",
  Turkish: "Türkçe",
  Ukrainian: "українська мова",
  Vietnamese: "Tiếng Việt",
};

interface LanguageOption {
  value: string;
  label: string;
  example?: string;
}

let languageOptions: LanguageOption[] = [
  "English",
  "Arabic",
  "Bengali",
  "Bulgarian",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Estonian",
  "Finnish",
  "French",
  "Georgian",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Korean",
  "Latvian",
  "Lithuanian",
  "Malay",
  "Maltese",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swedish",
  "Tamil",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
].map((item) => {
  return {
    value: item,
    label: item,
    example: languagesExampleByLanguage[item],
  };
});

if (isCn()) {
  languageOptions = [
    {
      label: "中文",
      value: "Chinese",
    },
    {
      label: "英语",
      value: "English",
    },
    {
      label: "阿拉伯语",
      value: "Arabic",
    },
    {
      label: "孟加拉语",
      value: "Bengali",
    },
    {
      label: "保加利亚语",
      value: "Bulgarian",
    },
    {
      label: "克罗地亚语",
      value: "Croatian",
    },
    {
      label: "捷克语",
      value: "Czech",
    },
    {
      label: "丹麦语",
      value: "Danish",
    },
    {
      label: "荷兰语",
      value: "Dutch",
    },
    {
      label: "爱沙尼亚语",
      value: "Estonian",
    },
    {
      label: "芬兰语",
      value: "Finnish",
    },
    {
      label: "法语",
      value: "French",
    },
    {
      label: "格鲁吉亚语",
      value: "Georgian",
    },
    {
      label: "德语",
      value: "German",
    },
    {
      label: "希腊语",
      value: "Greek",
    },
    {
      label: "希伯来语",
      value: "Hebrew",
    },
    {
      label: "印地语",
      value: "Hindi",
    },
    {
      label: "匈牙利语",
      value: "Hungarian",
    },
    {
      label: "印尼语",
      value: "Indonesian",
    },
    {
      label: "意大利语",
      value: "Italian",
    },
    {
      label: "日语",
      value: "Japanese",
    },
    {
      label: "韩语",
      value: "Korean",
    },
    {
      label: "拉脱维亚语",
      value: "Latvian",
    },
    {
      label: "立陶宛语",
      value: "Lithuanian",
    },
    {
      label: "马来语",
      value: "Malay",
    },
    {
      label: "马耳他语",
      value: "Maltese",
    },
    {
      label: "挪威语",
      value: "Norwegian",
    },
    {
      label: "波兰语",
      value: "Polish",
    },
    {
      label: "葡萄牙语",
      value: "Portuguese",
    },
    {
      label: "罗马尼亚语",
      value: "Romanian",
    },
    {
      label: "俄语",
      value: "Russian",
    },
    {
      label: "塞尔维亚语",
      value: "Serbian",
    },
    {
      label: "斯洛伐克语",
      value: "Slovak",
    },
    {
      label: "斯洛文尼亚语",
      value: "Slovenian",
    },
    {
      label: "西班牙语",
      value: "Spanish",
    },
    {
      label: "瑞典语",
      value: "Swedish",
    },
    {
      label: "泰米尔语",
      value: "Tamil",
    },
    {
      label: "泰语",
      value: "Thai",
    },
    {
      label: "土耳其语",
      value: "Turkish",
    },
    {
      label: "乌克兰语",
      value: "Ukrainian",
    },
    {
      label: "越南语",
      value: "Vietnamese",
    },
  ].map((item) => {
    return {
      ...item,
      example: languagesExampleByLanguage[item.value],
    };
  });
}

export { languageOptions };

export const imageRestoreStorageBucket = "user-generated-images";

export const otherUserAssets = "user-other-assets";

export const musicBucket = "user-generated-music";

export const publicBucket = "public";

export const constructPublicImageUrl = (path: string) => {
  return `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/${path}`;
};

export const generateEndingWord = "595e1773-16b3";
export const streamJsonSeparator = "19-b3";
export const streamVariationSeparator = "b3-19";
export const pageNumberSeparator = "b3ba";

export const textEnhancementOperation = {
  paraphrase: "paraphrase",
  lengthen: "lengthen",
  translate: "translate",
  shorten: "shorten",
  summarize: "summarize",
  write_outline: "write_outline",
  synonyms: "synonyms",
};

export const interactiveTextOperation: any = {
  expand: "op-expand",
  shorten: "op-shorten",
  paraphrase: "op-paraphrase",
  translate: "op-translate",
  summary: "op-summary",
  writeMore: "op-write-more",
  command: "op-command",
  conclusion: "op-conclusion",
  spellingGrammer: "op-spelling-grammer",
  formatting: "op-formatting",
  changeTone: "op-change-tone",
  simpleLanguage: "op-simple-language",
  addDepth: "op-add-depth",
};

export const getLanguagePrompt = (language?: string) => {
  const selectedExampleLanguage =
    language && languagesExampleByLanguage[language]
      ? `(${languagesExampleByLanguage[language]})`
      : "";
  if (!language || !selectedExampleLanguage || language === "English") {
    return "";
  }
  return `${language} language ${selectedExampleLanguage}`;
};

export const interactiveTextOperations = Object.values(
  interactiveTextOperation
);

export const imageSizes = ["256x256", "512x512", "768x768", "1024x1024"];

export const delleSizes = [imageSizes[0], imageSizes[3]];
export const allSdSizes = [imageSizes[1], imageSizes[2]];
export const allDelleSizes = [imageSizes[0], imageSizes[1], imageSizes[3]];
export const dalleStyles = ["Animal"];
export const twoYearInSeconds = 31536000 * 5;

export const getHasNegativePrompt = (config: Record<string, any>) => {
  const hasNegativePrompt =
    typeof config.negativePrompt === "string" &&
    config.negativePrompt &&
    config.negativePrompt.length > 0;
  return hasNegativePrompt;
};

export const oneDayInSeconds = 86400;

export enum FromType {
  fromChatFileUpload = "chat-file-upload",
  info = "info-base",
  webPage = "web-page",
}

let defaultCollectionName = "Untitled Collection";
let defaultPostName = "Untitled Post";
let markdownFileName = "content.md";
let custom = "custom";

if (isCn()) {
  custom = "自定义";
  markdownFileName = "内容.md";
  defaultCollectionName = "无名文档";
  defaultPostName = "无名文章";
}

export { defaultCollectionName, custom, markdownFileName, defaultPostName };
