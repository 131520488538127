import {
  dashboardRouteValues,
  serverRoutes,
  serverRoutesValues,
} from "client-server-shared/config/routes";

function safelyDecodeURIComponent(value: string, paramName: string) {
  try {
    return decodeURIComponent(value);
  } catch (error) {
    return value;
  }
}

function compilePath(
  path: string,
  caseSensitive = false,
  end = true
): [RegExp, string[]] {
  let paramNames: string[] = [];
  let regexpSource =
    "^" +
    path
      .replace(/\/*\*?$/, "") // Ignore trailing / and /*, we'll handle it below
      .replace(/^\/*/, "/") // Make sure it has a leading /
      .replace(/[\\.*+^$?{}|()[\]]/g, "\\$&") // Escape special regex chars
      .replace(/\/:(\w+)/g, (_: string, paramName: string) => {
        paramNames.push(paramName);
        return "/([^\\/]+)";
      });

  if (path.endsWith("*")) {
    paramNames.push("*");
    regexpSource +=
      path === "*" || path === "/*"
        ? "(.*)$" // Already matched the initial /, just match the rest
        : "(?:\\/(.+)|\\/*)$"; // Don't include the / in params["*"]
  } else if (end) {
    regexpSource += "\\/*$";
  } else if (path !== "" && path !== "/") {
    // If our path is non-empty and contains anything beyond an initial slash,

    regexpSource += "(?:(?=\\/|$))";
  } else {
    // Nothing to match for "" or "/"
  }

  let matcher = new RegExp(regexpSource, caseSensitive ? undefined : "i");

  return [matcher, paramNames];
}

export function matchPath(pattern: any, pathname: string): any {
  if (typeof pattern === "string") {
    pattern = { path: pattern, caseSensitive: false, end: true };
  }

  let [matcher, paramNames] = compilePath(
    pattern.path,
    pattern.caseSensitive,
    pattern.end
  );

  let match = pathname.match(matcher);
  if (!match) return null;

  let matchedPathname = match[0];
  let pathnameBase = matchedPathname.replace(/(.)\/+$/, "$1");
  let captureGroups = match.slice(1);
  let params = paramNames.reduce((memo, paramName, index) => {
    // We need to compute the pathnameBase here using the raw splat value
    // instead of using params["*"] later because it will be decoded then
    if (paramName === "*") {
      let splatValue = captureGroups[index] || "";
      pathnameBase = matchedPathname
        .slice(0, matchedPathname.length - splatValue.length)
        .replace(/(.)\/+$/, "$1");
    }

    memo[paramName] = safelyDecodeURIComponent(
      captureGroups[index] || "",
      paramName
    );
    return memo;
  }, {});

  return {
    params,
    pathname: matchedPathname,
    pathnameBase,
    pattern,
  };
}

export const isValidReturnUrl = (returnUrl: string) =>
  returnUrl &&
  [
    "https://junia.ngrok.dev",
    "https://junia.ai",
    "https://www.junia.ai",
    "https://www.heyjunia.cn",
    "https://heyjunia.cn",
    "https://junia-ai-git-preview-backup-user19961996-s-team.vercel.app",
    "https://junia-ai-cn-git-preview-backup-user19961996-s-team.vercel.app",
  ].some((url) => returnUrl.startsWith(url));

export const getURL = (path?: string) => {
  let url =
    process?.env?.NEXT_PUBLIC_SITE_URL ?? // Set this to your site URL in production env.
    process?.env?.NEXT_PUBLIC_VERCEL_URL ?? // Automatically set by Vercel.
    "http://localhost:3000/";
  // Make sure to include `https://` when not localhost.
  url = url.includes("http") ? url : `https://${url}`;
  // Make sure to including trailing `/`.
  url = url.charAt(url.length - 1) === "/" ? url : `${url}/`;

  let cleanPath = path
    ? path.charAt(0) === "/"
      ? path.substring(1, path.length)
      : path
    : "";
  // url = "https://junia.ngrok.dev/";
  return `${url}${cleanPath}`;
};

export const getCurrentFullPath = () => {
  let path = window.location.pathname;
  if (window.location.search) {
    path += window.location.search;
  }
  return getURL(path);
};

export const isValidDashboardRoute = (unknownUrl: string) => {
  return dashboardRouteValues.some((routeWithPattern) =>
    matchPath(routeWithPattern, unknownUrl)
  );
};

export const isLoginRoute = (unknownPathWithPattern: string) => {
  return [serverRoutes.login, serverRoutes.register].some(
    (routeWithPattern) => unknownPathWithPattern === routeWithPattern
  );
};

export const isValidServerRoute = (unknownPathWithPattern: string) => {
  return serverRoutesValues.some(
    (routeWithPattern) => unknownPathWithPattern === routeWithPattern
  );
};
